import { combineReducers } from 'redux'
import { PURGE } from 'redux-persist'
import AdminById from './state/AdminById/reducer'
import Auth from './state/Auth/reducer'
import DeviceById from './state/DeviceById/reducer'
import DeviceLogsById from './state/DeviceLogsById/reducer'
import LogCountById from './state/LogCountById/reducer'
import LoginForm from './state/LoginForm/reducer'
import SystemCache from './state/SystemCache/reducer'
import UserById from './state/UserById/reducer'

import { State } from './types'

const childReducers = combineReducers<State>({
  Auth,
  DeviceById,
  DeviceLogsById,
  LogCountById,
  LoginForm,
  UserById,
  AdminById,
  SystemCache,
})

const rootReducer = (state: State | undefined, action: any) => {
  if (action.type === PURGE) {
    state = undefined
  }
  return childReducers(state, action)
}

export default rootReducer
