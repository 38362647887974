import { Button, TextField, Typography } from '@material-ui/core'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { getLoginUser } from '../../../state/Auth/selectors'
import { updateYuatsuField } from '../../../state/Firebase/operations'
import { getUserYuatsuConfig } from '../../../state/UserById/selectors'

type Props = {}

type Fields = {
  pointMt: number
  pointMm: number
}

function PadPointForm(props: Props) {
  const user = useSelector(getLoginUser)
  const config = useSelector(getUserYuatsuConfig)

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik<Fields>({
    initialValues: {
      pointMt: config.pointMt,
      pointMm: config.pointMm,
    },
    validate: values => {
      const errors: FormikErrors<Fields> = {}

      if (
        typeof values.pointMm !== 'number' ||
        values.pointMm < 0 ||
        values.pointMm >= 5
      ) {
        errors.pointMm = '範囲外です(0~4)'
      }
      if (
        typeof values.pointMt !== 'number' ||
        values.pointMt < 0 ||
        values.pointMt >= 5
      ) {
        errors.pointMt = '範囲外です(0~4)'
      }
      return errors
    },
    onSubmit: (values: Fields, { setSubmitting, setErrors }) => {
      updateYuatsuField(user.id, values).then(() => {
        setSubmitting(false)
      })
    },
  })

  const change = (title, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(title, true, false)
  }

  const disabled =
    isSubmitting ||
    (values.pointMm === config.pointMm && values.pointMt === config.pointMt)

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr max-content',
        }}
      >
        <div>
          <Typography>小数点以下の桁数設定</Typography>

          <div style={{ display: 'flex' }}>
            <TextField
              id="pointMt"
              name="pointMt"
              type="number"
              helperText={touched.pointMt ? errors.pointMt : ''}
              error={touched.pointMt && Boolean(errors.pointMt)}
              label="液圧・荷重"
              InputProps={{ inputProps: { min: 0, max: 4 } }}
              value={values.pointMt}
              onChange={change.bind(null, 'pointMt')}
              fullWidth
            />
            <TextField
              style={{ display: user.allowDialGauge ? 'block' : 'none' }}
              id="pointMm"
              name="pointMm"
              type="number"
              helperText={touched.pointMm ? errors.pointMm : ''}
              error={touched.pointMm && Boolean(errors.pointMm)}
              label="変位"
              InputProps={{ inputProps: { min: 0, max: 4 } }}
              value={values.pointMm}
              onChange={change.bind(null, 'pointMm')}
              fullWidth
            />
          </div>
        </div>
        <Button
          color="primary"
          disabled={disabled}
          onClick={e => handleSubmit()}
        >
          変更する
        </Button>
      </div>
    </form>
  )
}

export default PadPointForm
