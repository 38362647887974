import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'
import _ from 'lodash'
import moment from 'moment'
import { historyPush, moveTopPage } from '../../containers/App/operations'
import { initializeFirebase } from '../../services/firebase'
import {
  Device,
  DeviceRaw,
  Log,
  LogRaw,
  Session,
  SessionRaw,
  ThunkAction,
  User,
  YuatsuConfig,
} from '../../types'
import { apiClient, toLog } from '../../utils'
import { decrypt, encrypt } from '../../utils/encrypt'
import * as authActions from '../Auth/actions'
import { saveLogin } from '../Auth/operations'
import { getLoginUser, getLoginUserDeviceIds } from '../Auth/selectors'
import { updateDevice } from '../DeviceById/actions'
// import * as actions from './actions'
import { persistor } from '../..'
import { saveDevice } from '../DeviceById/operations'
import { setLoginErrorMessage } from '../LoginForm/actions'
import { setDeviceLoading } from '../SystemCache/actions'
import { receiveUser } from '../UserById/actions'

const messageLoginError = setLoginErrorMessage(
  '認証できませんでした。ご登録いただいているメールアドレスとパスワードを入力してください。',
)

initializeFirebase()
const fdb = firebase.database()

async function convertLogin(
  email: string,
  password: string,
): Promise<string | undefined> {
  const res = await apiClient
    .post<{ password: string }>('convert_login', {
      email,
      password,
    })
    .catch(e => {
      return undefined
    })

  return res && res.data.password
}

export function passwordLogin(email: string, password: string) {
  return async dispatch => {
    const proPassword = await convertLogin(email, encrypt(password))

    if (!proPassword) {
      dispatch(messageLoginError)
      return
    }
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, decrypt(proPassword))
      .catch(e => {
        if (e.code === 'auth/wrong-password') {
          dispatch(messageLoginError)
        }
        return { user: null }
      })
  }
}

export function logout(nextPath?: string): ThunkAction {
  return async dispatch => {
    await firebase
      .auth()
      .signOut()
      .catch(console.error)
    await persistor.purge()
    await dispatch(authActions.logout())

    historyPush(nextPath || '/login')
  }
}

function authStateChanged(user: firebase.User | null, redirect: boolean) {
  return async dispatch => {
    if (!user) {
      return dispatch(authActions.loginFailed())
    }
    const userRef = fdb.ref(`user/${user.uid}`)
    const snapshot = await userRef.once('value')

    if (snapshot.exists()) {
      const user = snapshot.val()

      await dispatch(saveLogin(user))
      if (redirect) {
        moveTopPage()
      }
      return
    }
    dispatch(messageLoginError)
    dispatch(authActions.loginFailed())
  }
}

export function refInit(): ThunkAction {
  return async dispatch => {
    firebase.auth().onAuthStateChanged(user => {
      dispatch(authStateChanged(user, false))
    })
  }
}

const userSnapshotChanged = snapshot => dispatch => {
  if (!snapshot) {
    return
  }
  const user = snapshot.val()

  if (!user) {
    return
  }
  dispatch(receiveUser(user))
}

export function syncUserData(): ThunkAction {
  return async dispatch => {
    fdb
      .ref('user')
      .orderByChild('deleted')
      .equalTo(false)
      .on('value', snapshot => {
        if (!snapshot) {
          return
        }
        const users = snapshot.val()

        _.map(users, user => {
          dispatch(receiveUser(user))
        })
      })
  }
}

export function syncLoginUserData(): ThunkAction {
  return async (dispatch, getState) => {
    const userState = getLoginUser(getState())

    fdb.ref(`user/${userState.id}`).on('value', snapshot => {
      dispatch(userSnapshotChanged(snapshot))
    })
  }
}

function loadDevice(deviceId: string): ThunkAction {
  // console.log({ deviceId })
  return async dispatch => {
    const { deviceRef, sessionRef } = await getSessionRefs(deviceId)
    const deviceLastLogRef = sessionRef.child('lastLog')

    const device = (await deviceRef.once('value')).val() as DeviceRaw

    if (device.category !== 'yuatsu') return
    await dispatch(
      updateDevice({
        id: deviceId,
        data: device.data,
        info: device.info,
        currentSessionId: device.currentSessionId,
        selector: `${deviceId}__${device.currentSessionId}`,
        updatedAt: device.updatedAt,
      }),
    )

    fdb.ref(`device/${deviceId}/currentSessionId`).on('value', snap => {
      if (snap.val() !== device.currentSessionId) {
        dispatch(loadDevice(deviceId))
      }
    })

    deviceRef.on('value', snapshot => {
      if (!snapshot) {
        return
      }
      const device = snapshot.val() as Device

      dispatch(
        updateDevice({
          id: deviceId,
          data: device.data,
          info: device.info,
          currentSessionId: device.currentSessionId,
          selector: `${deviceId}__${device.currentSessionId}`,
          updatedAt: device.updatedAt,
        }),
      )
    })

    deviceLastLogRef.on('value', async (snapshot, id) => {
      const device = (await deviceRef.once('value')).val()

      if (!snapshot) return

      const logRaw: LogRaw | undefined = snapshot.val()

      if (!logRaw) return

      console.log('receive firebase', logRaw)

      const log = toLog(logRaw)

      dispatch(
        saveDevice({
          ..._.pick(device, [
            'data',
            'info',
            'updatedAt',
            'currentUserId',
            'currentSessionId',
          ]),
          selector: `${deviceId}__${device.currentSessionId}`,
          id: deviceId,
          lastLogs: { [device.currentSessionId]: log },
        }),
      )
    })
  }
}

export function loadDevices(): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(syncLoginUserData())
    const deviceIds = getLoginUserDeviceIds(getState())

    await dispatch(setDeviceLoading(true))
    await Promise.all(deviceIds.map(async id => await dispatch(loadDevice(id))))
    await dispatch(setDeviceLoading(false))
  }
}

export async function getSessionRefs(deviceId: string) {
  const deviceRef = fdb.ref(`device/${deviceId}`)
  const deviceRaw: DeviceRaw = (await deviceRef.once('value')).val()
  const { currentUserId, currentSessionId } = deviceRaw
  const sessionRef = fdb.ref(
    `user-device-session-log/${currentUserId}/${deviceId}/${currentSessionId}`,
  )

  return { deviceRef, deviceRaw, sessionRef }
}

const dayEndsUnix = (day: string) => {
  const m = moment(day, 'YYYY-MM-DD')
  const dayStart = m.startOf('day').unix() * 1000
  const dayEnd = m.endOf('day').unix() * 1000

  return { dayStart, dayEnd }
}

export async function loadLogs(deviceId: string, day: string): Promise<Log[]> {
  const { dayStart, dayEnd } = dayEndsUnix(day)
  const { sessionRef } = await getSessionRefs(deviceId)

  const deviceLogRef = sessionRef.child('logs')

  // sessionRef.child('info').on('value', () => {})
  const logsSnap = await deviceLogRef
    .orderByChild('timestamp')
    .startAt(dayStart)
    .endAt(dayEnd)
    .once('value')

  const logRaws: { [key: string]: LogRaw } = logsSnap.val()
  const logs: Log[] = _.map(logRaws, (log, key) => toLog(log, key))

  return logs
}

export async function updateDeviceCurrentUser(
  deviceId: string,
  newCurrentUserId: string,
) {
  const deviceRef = fdb.ref(`device/${deviceId}`)
  const device = (await deviceRef.once('value')).val()

  if (newCurrentUserId === device.currentUserId) {
    return
  }
  await fdb.ref(`user/${device.currentUserId}/deviceIds/${deviceId}`).set(null)
  await fdb.ref(`user/${newCurrentUserId}/deviceIds/${deviceId}`).set(true)
  await deviceRef.update({
    currentUserId: newCurrentUserId,
    currentSessionId: Number(device.currentSessionId) + 1,
  })
}

export function updateDeviceInfo(
  deviceId: string,
  fields: { [key: string]: any },
) {
  return () => {
    fdb
      .ref(`device/${deviceId}/info`)
      .update(
        _.pick(fields, ['status', 'priority', 'label', 'visible', 'memo']),
      )
    if (fields.currentUserId) {
      updateDeviceCurrentUser(deviceId, fields.currentUserId)
    }
  }
}

export type LinkageFields = {
  url: string
  headersText: string
}

export async function getSessions(user: User) {
  const sessionRef = fdb
    .ref(`user-device-session/${user.id}`)
    .orderByChild('category')
    .equalTo('yuatsu')
  const devicesById: {
    [deviceId: string]: { [sessionId: string]: SessionRaw }
  } = (await sessionRef.once('value')).val()

  const sessionsList: {
    deviceId: string
    sessions: Session[]
  }[] = Object.entries(devicesById).map(([deviceId, sessionById]) => {
    const sessions = _.map(
      sessionById,
      (session, id): Session => ({
        id,
        ...session,
      }),
    )

    return {
      deviceId,
      sessions: _.sortBy(sessions, [s => -s.createdAt]),
    }
  })

  return sessionsList
}

export async function loadSessionLogs(
  device: Device,
  start: number,
  end: number,
) {
  const logsSnap = await fdb
    .ref(
      `user-device-session-log/${device.currentUserId}/${device.id}/${device.currentSessionId}/logs`,
    )
    .orderByChild('timestamp')
    .startAt(start)
    .endAt(end)
    .once('value')

  if (!logsSnap.exists()) return []

  const logs: LogRaw[] = []

  // NOTE: for keep orders
  logsSnap.forEach(snap => {
    logs.push(snap.val() as LogRaw)
  })

  return logs
}

export function updateYuatsuField(uid: string, fields: Partial<YuatsuConfig>) {
  console.log(fields)

  return fdb.ref(`user/${uid}/yuatsu`).update(fields)
}
