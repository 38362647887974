import { Button, makeStyles } from '@material-ui/core'
import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Admin, Auth, User } from '../../../types'
import { useIsMobile } from '../../../components/useIsMobile'

export type Props = {
  user: User | Admin
  auth: Auth
  title?: string
  onClickTitle: () => void
  onClickLogout: (nextPath?: string) => void
}

const useStyles = makeStyles(() => ({
  root: { backgroundColor: 'rgb(44, 113, 186)', color: 'white' },
}))

const NavBar = (props: Props) => {
  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <div>
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <Typography
            onClick={props.onClickTitle}
            variant={isMobile ? 'body2' : 'h6'}
            color="inherit"
            style={{ flex: 1, cursor: 'pointer' }}
          >
            {props.title}
          </Typography>
          <Typography variant={isMobile ? 'body2' : 'h6'} color="inherit">
            {props.user.label} 様
          </Typography>
          <Button
            variant="text"
            onClick={() => props.onClickLogout('/login')}
            style={{ marginTop: 0 }}
            color="inherit"
          >
            ログアウト
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}

NavBar.defaultProps = { title: 'エキアツミエルカ' }

export default NavBar
