import _ from 'lodash'
import { getAdmin } from '../../state/AdminById/selectors'
import { getUser } from '../../state/UserById/selectors'
import { Admin, State, User } from '../../types'

export const getAuth = (state: State) => state.Auth
export const getLoading = (state: State) => state.Auth.loading
export const getLoginUser = (state: State): User => {
  if (state.Auth.loading || !state.Auth.authorized) {
    throw Error('not authorized')
  }
  return getUser(state, state.Auth.userId)
}

export const getAllowDialGauge = (state: State) => {
  const user = getLoginUser(state)

  return Boolean(user.allowDialGauge)
}

export const getLoginAdmin = (state: State): Admin => {
  if (state.Auth.loading || !state.Auth.authorized) {
    throw Error('not authorized')
  }
  return getAdmin(state, state.Auth.userId)
}

export const getLoginUserAdmin = (state: State): User | Admin => {
  return getLoginUser(state) || getLoginAdmin(state)
}

export const getLoginUserDeviceIds = (state: State): string[] => {
  return _.keys(getLoginUser(state).deviceIds) || []
}

export const getIsLogin = (state: State) =>
  !state.Auth.loading && state.Auth.authorized
