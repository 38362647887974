import { Button, Tooltip, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useIsMobile } from '../../components/useIsMobile'
import conf from '../../config'
import { getAllowDialGauge } from '../../state/Auth/selectors'
import { getRuler, getYuatsuDevice } from '../../state/DeviceById/selectors'
import { getUserYuatsuConfig } from '../../state/UserById/selectors'
import { Device, Log, State } from '../../types'
import { butteryJudge, round, toTon } from '../../utils'
import { useTonMode } from '../../utils/useLocalConfig'
import { totalGap } from './DevicePanel'
import LogGraphModal from './LogGraphModal'

const { colors } = conf
const defaultColor = '#000'

function getColor(ruler: number[] | false, v: number) {
  if (!ruler) return defaultColor
  for (let i = 0; i < ruler.length; i++) {
    if (v <= ruler[i]) return colors[i]
  }
  return colors[colors.length - 1]
}

function isSuspended(log?: Log) {
  const isNoData = typeof log?.fields?.mpa !== 'number'
  const day2ago = +new Date() - 2 * 24 * 60 * 60 * 1000
  const isOld = (log?.timestamp || 0) < day2ago

  return isNoData || isOld
}

function DeviceTileActive({ deviceId }: { deviceId: string }) {
  const ruler = useSelector(getRuler)?.[0] || false

  const [tonMode] = useTonMode()
  const config = useSelector(getUserYuatsuConfig)
  const [open, setOpen] = useState<boolean>(false)
  const allowDialGauge = useSelector(getAllowDialGauge)

  const device = useSelector<State, Device | undefined>(s =>
    getYuatsuDevice(s, deviceId),
  )
  const isMobile = useIsMobile()

  if (!device) return null
  const lastLog = device.lastLogs[device.currentSessionId]
  const width = (1 - totalGap) / config.col

  if (isSuspended(lastLog)) {
    return (
      <Wrap
        style={{
          background: '#A6A6A6',
          // @ts-ignore
          '--width': width,
        }}
        mc={config.col}
        data-enable-dialgauge={allowDialGauge}
      >
        <Typography className="device-id">{deviceId}</Typography>
        <div />
        <div />
        <div>
          <LogGraphModal
            device={device}
            modalOpen={open}
            handleCloeModal={() => setOpen(false)}
          />
          <Button onClick={() => setOpen(true)}>グラフ表示</Button>
        </div>
      </Wrap>
    )
  }

  const color = getColor(
    ruler,
    config.colorMode === 'mpa' ? lastLog.fields.mpa : lastLog.fields.len || 0,
  )
  const { lv, par } = butteryJudge(lastLog.fields.volt || 0)

  return (
    <Wrap
      style={{
        background: color,
        // @ts-ignore
        '--width': width,
      }}
      data-mobile={isMobile}
      mc={config.col}
      data-enable-dialgauge={allowDialGauge}
    >
      <Typography className="device-id">{deviceId}</Typography>
      <Tooltip title={`${par}%`}>
        <div className="buttery" data-lv={lv}>
          <span style={{ cursor: 'default' }}>aa</span>
        </div>
      </Tooltip>
      {tonMode ? (
        <Typography className="val">
          {round(
            toTon(lastLog.fields.mpa, config.pressureArea),
            config.pointMt,
          )}
          <span className="unit">t</span>
        </Typography>
      ) : (
        <Typography className="val">
          {round(lastLog.fields.mpa, config.pointMt)}
          <span className="unit">MPa</span>
        </Typography>
      )}
      <div>
        {allowDialGauge && lastLog.fields.len !== undefined && (
          <Typography className="gap">
            {round(lastLog.fields.len, config.pointMm)}mm
          </Typography>
        )}
      </div>
      <div>
        <LogGraphModal
          device={device}
          modalOpen={open}
          handleCloeModal={() => setOpen(false)}
        />
        <Button onClick={() => setOpen(true)}>グラフ表示</Button>
      </div>

      <Typography className="time">{lastLog.timeLabel}</Typography>
    </Wrap>
  )
}

function DeviceTilePassive() {
  return <Wrap mc={10} />
}

type Props = {
  deviceId?: string
}

function DeviceTile({ deviceId }: Props) {
  if (!deviceId) return <DeviceTilePassive />
  return <DeviceTileActive deviceId={deviceId} />
}

const Wrap = styled.div<{ mc: number }>`
  position: relative;
  background: ${defaultColor};
  height: 100%;
  width: calc(var(--width) * 100vw);
  border: solid gray 1px;
  text-align: center;
  display: grid;
  align-items: center;
  grid-template-rows: 13% 30% 0% 28% 27%;
  &[data-enable-dialgauge='true'] {
    grid-template-rows: 11% 28% 10% 24% 25%;
  }

  .device-id {
    padding: 0;
    font-size: calc((100vw * var(--width) * 0.08));
  }
  .val {
    padding: 0;
    font-size: calc((100vw * var(--width) * 0.18));
    .unit {
      font-size: calc((100vw * var(--width) * 0.14));
    }
  }
  .gap {
    padding: 0;
    font-size: calc((100vw * var(--width) * 0.13));
  }
  .time {
    padding: 0;
    font-size: calc((100vw * var(--width) * 0.13));
    line-height: calc((100vw * var(--width) * 0.14));
  }
  button {
    font-size: calc((100vw * var(--width) * 0.06));
    line-height: calc((100vw * var(--width) * 0.07));
    margin: calc((100vw * var(--width) * 0.02));
    padding: calc((100vw * var(--width) * 0.02));
    border-radius: calc((100vw * var(--width) * 0.02));
    width: 60%;
    min-width: 60%;
    max-width: 60%;
  }
  &[data-mobile='true'] {
    /* .time {
      font-size: calc((100vw * var(--width) * 0.1));
      line-height: calc((100vw * var(--width) * 0.11));
      padding-left: calc(var(--width) * 100vw * 0.1);
      padding-right: calc(var(--width) * 100vw * 0.1);
    } */
  }
  .buttery {
    position: absolute;
    top: calc((100vw * var(--width) * 0.02));
    right: calc((100vw * var(--width) * 0.02));
    width: 12%;
    height: 7%;
    background: url('/buttery-0.png');
    &[data-lv='3'] {
      background: url('/buttery-3.png');
    }
    &[data-lv='2'] {
      background: url('/buttery-2.png');
    }
    &[data-lv='1'] {
      background: url('/buttery-1.png');
    }
    span {
      opacity: 0;
    }
    background-size: 100% 100% !important;
  }
`

export default DeviceTile
