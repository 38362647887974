import { Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getDeviceLoading } from '../../state/SystemCache/selectors'
import { getUserYuatsuConfig } from '../../state/UserById/selectors'
import { gridCellKeys } from '../../utils'
import DeviceTile from './DeviceTile'

type Props = {}

export const totalGap = 0.02

function DevicePanel(props: Props) {
  const config = useSelector(getUserYuatsuConfig)
  const loading = useSelector(getDeviceLoading)

  const { cellIds, gridTemplateAreas } = gridCellKeys(config.col, config.row)

  if (loading) return <Typography>ロード中</Typography>

  return (
    <div>
      <CellWrap mc={config.col} style={{ gridTemplateAreas }}>
        {cellIds.map(([ri, ci, cellId, gridArea]) => (
          <Cell key={cellId} style={{ gridArea }} mc={config.col}>
            {config.row <= ri || config.col <= ci ? null : (
              <DeviceTile deviceId={config.cells[cellId]} />
            )}
          </Cell>
        ))}
      </CellWrap>
      <Footer>
        <Typography variant="h5"> {config.memoLeft} </Typography>
        <Typography variant="h5"> {config.memoRight} </Typography>
      </Footer>
    </div>
  )
}

const CellWrap = styled.div<{ mc: number }>`
  padding: 0 ${p => (totalGap * 100) / p.mc}vw;
  margin-top: 16px;
  grid-gap: calc(${totalGap} * 100vw / ${p => p.mc});
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(${p => p.mc}, 1fr);
  overflow: hidden;
`

const Cell = styled.div<{ mc: number }>`
  overflow: hidden;
  height: calc((100vw - 16px - 8px * ${p => p.mc - 1}) / ${p => p.mc});
`
const Footer = styled.div`
  margin-top: 16px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
`

export default DevicePanel
