import { Button, Modal } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { ModalCard } from '../../components'
import { getDeviceLoading } from '../../state/SystemCache/selectors'
import AlertConfigForm from './AlertConfigForm'

type Props = {
  modalOpen: boolean
  handleConcreteModalClose: () => void
}

function AlertConfigModal(props: Props) {
  const loading = useSelector(getDeviceLoading)

  if (loading) return null

  return (
    <Modal open={props.modalOpen} onClose={props.handleConcreteModalClose}>
      <ModalCard>
        <AlertConfigForm />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '3rem',
          }}
        >
          <Button onClick={props.handleConcreteModalClose}>閉じる</Button>
        </div>
      </ModalCard>
    </Modal>
  )
}

export default AlertConfigModal
