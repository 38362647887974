import { actionCreatorFactory } from 'typescript-fsa'
import { SystemCache } from '../../types'

const actionCreator = actionCreatorFactory()

export const updateSystemCache = actionCreator<Partial<SystemCache>>(
  'updateSystemCache',
)

export const updateSystemCacheToggles = actionCreator<{
  [key: string]: boolean
}>('updateSystemCacheToggles')

export const setDeviceLoading = actionCreator<boolean>('setDeviceLoading')
export const clearSystemCache = actionCreator('clearSystemCache')
