import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { SystemCache } from '../../types'

import * as actions from './actions'

export type State = SystemCache

const initialState: State = {
  deviceLoading: true,
  toggles: {},
  showName: true,
}

export default reducerWithInitialState<State>(initialState)
  .case(actions.setDeviceLoading, (state, deviceLoading) => {
    return { ...state, deviceLoading }
  })
  .case(actions.updateSystemCache, (state, systemCache) => {
    return { ...state, ...systemCache }
  })
  .case(actions.updateSystemCacheToggles, (state, toggles) => {
    return { ...state, toggles: { ...state.toggles, ...toggles } }
  })
  .case(actions.clearSystemCache, () => {
    return initialState
  })
