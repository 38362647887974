import { Button, TextField, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import config from '../../../config'
import { getLoginUser } from '../../../state/Auth/selectors'
import { updateYuatsuField } from '../../../state/Firebase/operations'
import { getUserYuatsuConfig } from '../../../state/UserById/selectors'
import { UpdateRowColField } from '../../../types'
import { getGridCellIds } from '../../../utils'

const { maxCol, maxRow } = config

type Props = {}

type Fields = UpdateRowColField
const fieldStyle = { width: '4rem', margin: '0px 4px' }
const middleStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}

function RowColForm(props: Props) {
  const user = useSelector(getLoginUser)
  const config = useSelector(getUserYuatsuConfig)

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik<Fields>({
    initialValues: { row: config.row, col: config.col },
    validate: values => {
      const errors: FormikErrors<Fields> = {}

      if (values.row < 1 || maxRow < values.row) {
        errors.row = `1〜${maxRow}の範囲にしてください。`
      }
      if (values.col < 1 || maxCol < values.col) {
        errors.col = `1〜${maxCol}の範囲にしてください。`
      }
      return errors
    },
    onSubmit: (values: Fields, { setSubmitting, setErrors }) => {
      const cellIds = getGridCellIds()
      const cells = { ...config.cells }

      cellIds.forEach(([ri, ci, cellId, gridArea]) => {
        if (values.col <= ci || values.row <= ri) delete cells[cellId]
      })

      updateYuatsuField(user.id, { ...values, cells }).then(() => {
        setSubmitting(false)
      })
    },
  })

  const change = (title, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(title, true, false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="caption">表示エリア設定</Typography>
      <div style={{ display: 'flex' }}>
        <Typography style={middleStyle}>縦</Typography>
        <TextField
          name="row"
          type="number"
          variant="outlined"
          size="small"
          style={fieldStyle}
          InputProps={{ inputProps: { min: 1, max: maxRow } }}
          helperText={touched.row ? errors.row : ''}
          error={touched.row && Boolean(errors.row)}
          value={values.row}
          onChange={change.bind(null, 'row')}
        />
        <Typography style={middleStyle}>列 x 横</Typography>
        <TextField
          name="col"
          type="number"
          variant="outlined"
          size="small"
          style={fieldStyle}
          InputProps={{ inputProps: { min: 1, max: maxCol } }}
          helperText={touched.col ? errors.col : ''}
          error={touched.col && Boolean(errors.col)}
          value={values.col}
          onChange={change.bind(null, 'col')}
        />

        <Typography style={{ ...middleStyle, flex: 1 }}>列</Typography>
        <Button
          color="primary"
          disabled={
            isSubmitting ||
            !!errors.row ||
            !!errors.col ||
            (values.col === config.col && values.row === config.row)
          }
          onClick={e => handleSubmit()}
        >
          変更する
        </Button>
      </div>
    </form>
  )
}

export default RowColForm
