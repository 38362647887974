import { Typography } from '@material-ui/core'
import React from 'react'
import TitleForm from './TitleForm'
import RowColForm from './RowColForm'
import CellsForm from './CellsForm'
import PressureAreaForm from './PressureAreaForm'
import ScaleForm from './ScaleForm'
import PadPointForm from './PadPointForm'

type Props = {}

function ConfigForm(props: Props) {
  return (
    <div style={{ padding: '12px' }}>
      <Typography variant="h4">表示設定</Typography>
      <TitleForm />
      <ScaleForm />
      <PadPointForm />
      <RowColForm />
      <CellsForm />
      <PressureAreaForm />
    </div>
  )
}

export default ConfigForm
