import _ from 'lodash'
import { Device, Log, State } from '../../types'
import { isNonUndefined, toDivs } from '../../utils'
import { getLoginUserDeviceIds } from '../Auth/selectors'
import { getDeviceLoading } from '../SystemCache/selectors'
import { getUser, getUserYuatsuConfig } from '../UserById/selectors'

export const getDevice = (state: State, deviceId: string) =>
  state.DeviceById[deviceId]
export const getDeviceIds = (state: State) => _.keys(state.DeviceById)
export const getDeviceById = (state: State) => state.DeviceById

// ConcreteMonitor

export const getYuatsuDevices = (state: State) => {
  const deviceIds = getLoginUserDeviceIds(state)

  return _.filter(
    deviceIds.map(did => getYuatsuDevice(state, did)),
    v => !!v,
  ) as Device[]
}

export const getCurrentUser = (state: State, deviceId: string) => {
  const device = getDevice(state, deviceId)

  return getUser(state, device.currentUserId)
}

export const getYuatsuDevice = (state: State, deviceId: string) => {
  const device = getDevice(state, deviceId)

  if (!device) {
    return undefined
  }

  return convertYuatsuMonitorDevice(device)
}

export const convertYuatsuLog = (log: any): Log => {
  return log
}

const convertYuatsuMonitorDevice = (device: any): Device => {
  const info = device.info || {}

  return {
    ...device,
    lastLogs: _.mapValues(device.lastLogs, convertYuatsuLog),
    info: {
      priority: info.priority || 0,
      label: info.label || '',
      visible: info.visible || false,
      threasholds: info.threasholds,
      memo: info.memo || '',
      token: info.token || '',
      keepedStr: `none yuatsu`,
      keeped: true,
      keeped25m: true,
      status: info.status || undefined,
    },
  }
}

export const getRuler = (state: State): [number[], number[]] | false => {
  const config = getUserYuatsuConfig(state)
  const max = config.maxMpa
  const min = config.minMpa

  return [
    toDivs(min, max, 6),
    toDivs(
      (min * config.pressureArea) / 98,
      (max * config.pressureArea) / 98,
      6,
    ),
  ]
}

export const getRulerByCalc = (state: State): [number[], number[]] | false => {
  const config = getUserYuatsuConfig(state)

  if (getDeviceLoading(state)) return false
  const ids = Object.values(config.cells).filter(Boolean)
  const nums = ids
    .map(id => getYuatsuDevice(state, id))
    .filter(isNonUndefined)
    .map(d => d.lastLogs?.[d.currentSessionId]?.fields.mpa)
    .filter(isNonUndefined)

  if (nums.length === 0) return false

  const max = Math.max(...nums)
  const min = Math.min(...nums)

  return [
    toDivs(min, max, 8),
    toDivs(
      (min * config.pressureArea) / 98,
      (max * config.pressureArea) / 98,
      8,
    ),
  ]
}
