import { Typography } from '@material-ui/core'
import React from 'react'
import AlertForm from './AlertForm'

type Props = {}

function AlertConfigForm(props: Props) {
  return (
    <div style={{ padding: '12px' }}>
      <Typography variant="h4">アラート設定</Typography>
      <AlertForm />
    </div>
  )
}

export default AlertConfigForm
