import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import config from '../config'
import { Log, LogRaw, GraphData } from '../types'

export const timeLabel = (timestamp: number) => {
  const m = moment(timestamp)

  return m.format(`YYYY/MM/DD HH:mm:ss`)
}

export const toLog = (old: LogRaw, id: string = '-'): Log => {
  return {
    id,
    timestamp: old.timestamp,
    timeLabel: timeLabel(old.timestamp),
    fields: old.fields,
  }
}

export function makeCSV(
  logs: GraphData,
  vlabel: string,
  allowDg: boolean,
): string {
  const log = logs[0]

  if (!log) {
    return 'noLog'
  }
  const head = ['timestamp', vlabel, '変位(mm)']

  const toLine = (log: GraphData[number]) => {
    return [moment(log[0]).format('YYYY-MM-DD HH:mm:ss'), log[1], log[2]].map(
      String,
    )
  }
  const cells = [head, ...logs.map(toLine)].map(row =>
    row.slice(0, allowDg ? 3 : 2),
  )

  return cells.map(vs => vs.join(',')).join('\r\n')
}

export function download(text: string, name: string) {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([bom, text], { type: 'text/csv' })
  const url = (window.URL || window.webkitURL).createObjectURL(blob)

  const a = document.createElement('a')

  a.setAttribute('href', url)
  a.setAttribute('download', name)
  document.body?.appendChild(a)
  setTimeout(() => {
    a.click()
    a.remove()
  }, 100)
}
export const apiClient = axios.create({ baseURL: config.apiHost })

export const toCellId = (ci: number, ri: number) => `${ri}_${ci}`

export function isNonUndefined<T>(v: T | undefined): v is T {
  return v !== undefined
}
export function isString(v: unknown): v is string {
  return !!v && typeof v === 'string' && v !== null
}

const range = (v: number) => Array.from(Array(v), (v, k) => k)

export const toDivs = (min: number, max: number, div: number) => {
  const d = max - min
  const unit = d / (div - 1)

  return [...range(div).map(i => min + i * unit)]
}

export const round = (v: number, rv: number = 1) => (v || 0).toFixed(rv)

export const getGridCellIds = (col = config.maxCol, row = config.maxRow) => {
  return _.flatten(
    _.range(row).map(ri =>
      _.range(col).map(
        ci => [ri, ci, toCellId(ri, ci), `a${ri}${ci}`] as const,
      ),
    ),
  )
}

export const gridCellKeys = (col = config.maxCol, row = config.maxRow) => {
  const cellIds = getGridCellIds(col, row)
  const gridTemplateAreas = _.range(row)
    .map(
      ri =>
        `"${_.range(col)
          .map(ci => `a${ri}${ci}`)
          .join(' ')}"`,
    )
    .join(' ')

  return { cellIds, gridTemplateAreas } as const
}

export const gridCellKeysMobile = (
  col = config.maxCol,
  row = config.maxRow,
) => {
  const cellIds = getGridCellIds(col, row)
  const gridTemplateAreas = _.range(row)
    .map(
      ri =>
        `${_.range(col)
          .map(
            ci =>
              `"${'- '.repeat(ci)} ${`a${ri}${ci} `.repeat(col)} ${'- '.repeat(
                col - ci,
              )}"`,
          )
          .join(' ')}`,
    )
    .join(' ')

  console.log(gridTemplateAreas)

  return { cellIds, gridTemplateAreas } as const
}

export function toLocalIso(date: Date): string {
  const pad = function(str: string): string {
    return ('0' + str).slice(-2)
  }
  const year = date.getFullYear().toString()
  const month = pad((date.getMonth() + 1).toString())
  const day = pad(date.getDate().toString())
  const hour = pad(date.getHours().toString())
  const min = pad(date.getMinutes().toString())
  const sec = pad(date.getSeconds().toString())

  return `${year}-${month}-${day}T${hour}:${min}:${sec}`
}

export const dateJpShift = (date: Date) => {
  return new Date(+date + 9 * 60 * 60 * 1000)
}

const BUTTERY_HI = 4.1
const BUTTERY_LO = 3.3

export const butteryPar = (vol: number) =>
  Math.max(
    0,
    Math.min(
      100,
      Math.floor(((vol - BUTTERY_LO) / (BUTTERY_HI - BUTTERY_LO)) * 100),
    ),
  )

export const butteryLv = (p: number) => {
  if (p > 66) return 3
  if (p > 33) return 2
  if (p > 0) return 1
  return 0
}

export const butteryJudge = (vol: number) => {
  const par = butteryPar(vol)

  return { par, lv: butteryLv(par) }
}

export const toTon = (mpa: number | undefined, pressureArea: number) =>
  Number((((mpa || 0) * pressureArea) / 98).toFixed(3))

export const fromTon = (ton: number, pressureArea: number) =>
  (ton * 98) / pressureArea
