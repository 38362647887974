import { Button, TextField } from '@material-ui/core'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { getLoginUser } from '../../../state/Auth/selectors'
import { updateYuatsuField } from '../../../state/Firebase/operations'
import { getUserYuatsuConfig } from '../../../state/UserById/selectors'

type Props = {}

type Fields = {
  title: string
}

function TitleForm(props: Props) {
  const user = useSelector(getLoginUser)
  const config = useSelector(getUserYuatsuConfig)

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik<Fields>({
    initialValues: {
      title: config.title,
    },
    validate: values => {
      const errors: FormikErrors<Fields> = {}

      if (!values.title) {
        errors.title = '必須です'
      }
      return errors
    },
    onSubmit: (values: Fields, { setSubmitting, setErrors }) => {
      updateYuatsuField(user.id, values).then(() => {
        setSubmitting(false)
      })
    },
  })

  const change = (title, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(title, true, false)
  }

  const disabled =
    isSubmitting || !!errors.title || values.title === config.title

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr max-content',
        }}
      >
        <TextField
          id="title"
          name="title"
          helperText={touched.title ? errors.title : ''}
          error={touched.title && Boolean(errors.title)}
          label="現場名"
          value={values.title}
          onChange={change.bind(null, 'title')}
          fullWidth
        />
        <Button
          color="primary"
          disabled={disabled}
          onClick={e => handleSubmit()}
        >
          変更する
        </Button>
      </div>
    </form>
  )
}

export default TitleForm
