const {
  NODE_ENV,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_SERVER_KEY,
  REACT_APP_GIT_REV,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  STORYBOOK_GOOGLE_MAPS_API_KEY,
  REACT_APP_ENCRYPT_KEY,
  REACT_APP_API_HOST,
  REACT_APP_ENV,
} = process.env

const isDev = NODE_ENV === 'development' || REACT_APP_ENV === 'development'

const configDevelopment = {} as const
const configProduction: typeof configDevelopment = {}
const envConfig = isDev ? configDevelopment : configProduction

export const formKey = 'mclient'

const config = {
  isDev,
  tabBarHeight: 40,
  gitrev: REACT_APP_GIT_REV || '',
  firebase: {
    apiKey: REACT_APP_FIREBASE_API_KEY || '',
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    databaseURL: REACT_APP_FIREBASE_DATABASE_URL || '',
    projectId: REACT_APP_FIREBASE_PROJECT_ID || '',
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    serverKey: REACT_APP_FIREBASE_SERVER_KEY || '',
  },
  clockIntervalMs: 5000,
  googleMaps: {
    apiKey:
      REACT_APP_GOOGLE_MAPS_API_KEY || STORYBOOK_GOOGLE_MAPS_API_KEY || '',
    zoom: 15,
  },
  apiHost: REACT_APP_API_HOST || '',
  encryptKey: REACT_APP_ENCRYPT_KEY || '',
  concrete: {},
  colors: [
    '#2C71BA',
    '#4AAEEA',
    '#4CAD5B',
    '#9FCD63',
    '#FEFE54',
    '#F6C143',
    '#ED3423',
  ],
  maxCol: 10,
  maxRow: 5,
  ...envConfig,
}

export default config
