import { Button, Modal } from '@material-ui/core'
import React from 'react'
import { ModalCard } from '../../components'
import { Device } from '../../types'
import LogGraph from './LogGraph'

type Props = {
  device: Device
  modalOpen: boolean
  handleCloeModal: () => void
}

function LogGraphModal(props: Props) {
  return (
    <Modal open={props.modalOpen} onClose={props.handleCloeModal}>
      <ModalCard
        style={{
          display: 'grid',
          gridTemplateRows: '1fr max-content',
        }}
      >
        <LogGraph device={props.device} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '100px',
          }}
        >
          <Button onClick={props.handleCloeModal}>閉じる</Button>
        </div>
      </ModalCard>
    </Modal>
  )
}

export default LogGraphModal
