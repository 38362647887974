import { Button, Modal, Typography } from '@material-ui/core'
import React from 'react'
import { ModalCard } from '../../components'
import config from '../../config'
import ConcreteConfigForm from './ConfigForm'

type Props = {
  modalOpen: boolean
  handleConcreteModalClose: () => void
}

function ViewConfigModal(props: Props) {
  return (
    <Modal open={props.modalOpen} onClose={props.handleConcreteModalClose}>
      <ModalCard>
        <ConcreteConfigForm />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={props.handleConcreteModalClose}>閉じる</Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '5px',
          }}
        >
          <Typography style={{ color: '#333', marginBottom: '100px' }}>
            ビルドバージョン: {config.gitrev}
          </Typography>
        </div>
      </ModalCard>
    </Modal>
  )
}

export default ViewConfigModal
