import { noop } from 'lodash'
import React, { createContext, useContext } from 'react'
import { useLocalStorage } from './useLocalStorage'

type Config = {
  tonMode: boolean
}
const defaultConfig = {
  tonMode: false,
}

const ProfileContext = createContext<
  [Config, (config: Config) => void] | undefined
>(undefined)

export const ConfigProvider = ({ children }) => {
  const ctxValue = useLocalStorage<Config>('m-y-config', defaultConfig)

  return (
    <ProfileContext.Provider value={ctxValue}>
      {children}
    </ProfileContext.Provider>
  )
}

export const useConfig = () => useContext(ProfileContext)
export const useTonMode = () => {
  const [config, setConfig] = useConfig() || ([defaultConfig, noop] as const)

  return [
    config.tonMode,
    (tonMode: boolean) => setConfig({ ...config, tonMode }),
  ] as const
}
