import { Typography } from '@material-ui/core'
import * as React from 'react'
import { useSeconds } from 'use-seconds'
import moment from 'moment'

const TimestampLabel = () => {
  const [date] = useSeconds()
  const timestampStr = moment(date).format('YYYY-MM-DD HH:mm:ss')

  return <Typography variant="body1">{timestampStr}</Typography>
}

export default TimestampLabel
