import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import config from '../../../config'
import { getLoginUser } from '../../../state/Auth/selectors'
import { getDeviceById } from '../../../state/DeviceById/selectors'
import { updateYuatsuField } from '../../../state/Firebase/operations'
import { getDeviceLoading } from '../../../state/SystemCache/selectors'
import { getUserYuatsuConfig } from '../../../state/UserById/selectors'
import { UpdateCellsField } from '../../../types'
import { gridCellKeys, gridCellKeysMobile, isString } from '../../../utils'
import { useIsMobile } from '../../../components/useIsMobile'

const { maxCol } = config

type Props = {}

type Fields = UpdateCellsField

function CellsForm(props: Props) {
  const user = useSelector(getLoginUser)
  const config = useSelector(getUserYuatsuConfig)
  const deviceById = useSelector(getDeviceById)
  const loading = useSelector(getDeviceLoading)
  const devices = Object.values(deviceById)
  const isMobile = useIsMobile()

  const initialValues = {
    cells: config.cells,
    memoLeft: config.memoLeft,
    memoRight: config.memoRight,
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormik<Fields>({
    initialValues,
    enableReinitialize: true,
    validate: values => {
      const errors: FormikErrors<Fields> = {}

      return errors
    },
    onSubmit: (values: Fields, { setSubmitting, setErrors }) => {
      updateYuatsuField(user.id, values).then(() => {
        setSubmitting(false)
      })
    },
  })

  const change = (title, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(title, true, false)
  }

  const isChanged = JSON.stringify(values) === JSON.stringify(initialValues)
  const disabled =
    isSubmitting || Object.values(errors).some(Boolean) || isChanged

  const { cellIds, gridTemplateAreas } = isMobile
    ? gridCellKeysMobile()
    : gridCellKeys()

  if (loading) return <Typography> ロード中です。 </Typography>
  if (!devices.length)
    return <Typography>デバイスが割り振られていません。 </Typography>

  const usedCells = _.invert(values.cells)
  const otherDevices = devices.filter(d => !usedCells[d.id])
  // const deviceById = _.keyBy(devices, 'id')

  return (
    <form onSubmit={handleSubmit}>
      <Frame>
        <CellWrap
          style={
            isMobile ? { gridTemplateColumns: '1fr' } : { gridTemplateAreas }
          }
        >
          {cellIds.map(([ri, ci, cellId, gridArea]) => (
            <Cell
              key={cellId}
              style={isMobile ? { height: '40px' } : { gridArea }}
              data-disactive={config.row <= ri || config.col <= ci}
            >
              {isMobile && (
                <div style={{ width: '40px' }}>
                  <Typography>
                    {ri + 1}-{ci + 1}
                  </Typography>
                </div>
              )}
              <FormControl style={{ width: '100%', marginTop: '8px' }}>
                <Select
                  value={values.cells[cellId] || ''}
                  label={`${ri}行${ci}列`}
                  disabled={config.row <= ri || config.col <= ci}
                  SelectDisplayProps={{
                    style: {
                      padding: '0 12px 0 2px',
                      fontSize: '12px',
                      wordBreak: 'break-all',
                      whiteSpace: 'normal',
                    },
                  }}
                  onChange={({ target: { value } }) => {
                    const newCells = { ...values.cells }

                    if (isString(value)) {
                      newCells[cellId] = value
                    } else {
                      delete newCells[cellId]
                    }
                    setFieldValue('cells', newCells)
                  }}
                >
                  <MenuItem key={''} value={''}>
                    ---
                  </MenuItem>
                  {values.cells[cellId] && (
                    <MenuItem
                      key={values.cells[cellId]}
                      value={values.cells[cellId]}
                    >
                      {values.cells[cellId]}
                    </MenuItem>
                  )}
                  {otherDevices.map(device => (
                    <MenuItem key={device.id} value={device.id}>
                      {device.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Cell>
          ))}
        </CellWrap>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            id="memoLeft"
            name="memoLeft"
            helperText={touched.memoLeft ? errors.memoLeft : ''}
            error={touched.memoLeft && Boolean(errors.memoLeft)}
            label="自由記入欄"
            value={values.memoLeft}
            onChange={change.bind(null, 'memoLeft')}
            fullWidth
          />
          <TextField
            id="memoRight"
            name="memoRight"
            helperText={touched.memoRight ? errors.memoRight : ''}
            error={touched.memoRight && Boolean(errors.memoRight)}
            label="自由記入欄"
            value={values.memoRight}
            onChange={change.bind(null, 'memoRight')}
            fullWidth
          />
        </div>
      </Frame>
      <div style={{ textAlign: 'right' }}>
        <Button
          color="primary"
          disabled={disabled}
          onClick={e => handleSubmit()}
        >
          変更する
        </Button>
      </div>
    </form>
  )
}

export default CellsForm

const CellWrap = styled.div`
  padding: 0;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(${maxCol}, 1fr);
  grid-gap: 8px;
  overflow: hidden;
`

const Cell = styled.div`
  display: flex;
  align-items: flex-end;
  border: solid 1px #000;
  overflow: hidden;
  height: 70px;
  &[data-disactive='true'] {
    background: #bbb;
  }
`
const Frame = styled.div`
  margin: 4px -8px 0;
  border: solid 1px;
  padding: 4px;
`
