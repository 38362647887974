import { Button, TextField, Typography } from '@material-ui/core'
import { round } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Chart from 'react-google-charts'
import { useDispatch, useSelector } from 'react-redux'
import { useMeasure } from 'react-use'
import styled from 'styled-components'
import { useIsMobile } from '../../../components/useIsMobile'
import { getAllowDialGauge } from '../../../state/Auth/selectors'
import { loadSessionLogs } from '../../../state/Firebase/operations'
import { getUserYuatsuConfig } from '../../../state/UserById/selectors'
import { Device, GraphData } from '../../../types'
import { download, makeCSV, toLocalIso } from '../../../utils'
import { useTonMode } from '../../../utils/useLocalConfig'

const Wrap = styled.div`
  display: grid;
  grid-template-rows: max-content max-content 1fr;
`

type Props = {
  device: Device
}
type GraphMode = 'mpa' | 't'

const initMin = 0
const initMinMm = 'auto'
const initMax = 'auto'

const parseNum = (v: string) => {
  const n = parseInt(v)

  return isNaN(n) ? 'auto' : n
}

type SizeOption = {
  min: number | 'auto'
  max: number | 'auto'
  min2: number | 'auto'
  max2: number | 'auto'
}
function LogGraph({ device }: Props) {
  const [mode, setMode] = useState<0 | 168 | 24>(24)
  const [{ start, end }, setRange] = useState<{ start: Date; end: Date }>({
    start: new Date(+new Date() - mode * 60 * 60 * 1000),
    end: new Date(),
  })

  useEffect(() => {
    if (mode === 0) return
    const start = new Date(+new Date() - mode * 60 * 60 * 1000)
    const end = new Date()

    setRange({ start, end })
    if (startRef.current) startRef.current.value = toLocalIso(start)
    if (endRef.current) endRef.current.value = toLocalIso(end)
  }, [mode])

  const [size, setSize] = useState<SizeOption>({
    min: initMin,
    max: initMax,
    min2: initMinMm,
    max2: initMax,
  })
  const startRef = useRef<HTMLInputElement>(null)
  const endRef = useRef<HTMLInputElement>(null)
  const isMobile = useIsMobile()
  const [tonMode, setTonMode] = useTonMode()
  const dispatch = useDispatch()
  const [gmode, setGmode] = useState<GraphMode>(tonMode ? 't' : 'mpa')
  const allowDg = useSelector(getAllowDialGauge)

  useEffect(() => {
    setTonMode(gmode === 't')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gmode, dispatch])

  const changeSize = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: keyof typeof size,
  ) => {
    const num = e.target?.value

    if (num === undefined) return
    setSize(v => ({ ...v, [key]: parseNum(num) }))
  }
  const buttons = (
    <div>
      <Button
        color={gmode === 'mpa' ? 'primary' : 'default'}
        style={{ lineHeight: '1.2rem', width: '90px' }}
        onClick={() => setGmode('mpa')}
      >
        液圧
        <br />
        (MPa)
      </Button>
      <Button
        color={gmode === 't' ? 'primary' : 'default'}
        style={{ lineHeight: '1.2rem', width: '90px' }}
        onClick={() => setGmode('t')}
      >
        荷重
        <br />
        (t)
      </Button>
    </div>
  )

  return (
    <Wrap>
      <div
        style={{
          display: 'flex',
          gridTemplateColumns: '1fr max-content',
        }}
      >
        <Typography variant="h4">{device.id}</Typography>
        {!isMobile && buttons}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr max-content',
        }}
      >
        <div>
          <div>
            <Typography>{unitsMap[gmode].join('')}</Typography>
            <TextField
              label={'下限'}
              size="small"
              defaultValue={initMin}
              onChange={e => changeSize(e, 'min')}
            ></TextField>
            <TextField
              label={'上限'}
              size="small"
              defaultValue={initMax}
              onChange={e => changeSize(e, 'max')}
            ></TextField>
          </div>
          <div
            style={{ marginTop: '12px', display: allowDg ? 'block' : 'none' }}
          >
            <Typography>{unitGap.join('')}</Typography>
            <TextField
              label={'下限'}
              size="small"
              defaultValue={initMinMm}
              onChange={e => changeSize(e, 'min2')}
            ></TextField>
            <TextField
              label={'上限'}
              size="small"
              defaultValue={initMax}
              onChange={e => changeSize(e, 'max2')}
            ></TextField>
          </div>
        </div>
        <div>
          <div
            style={
              isMobile ? { display: 'grid', width: '100%', gap: '8px' } : {}
            }
          >
            {isMobile && buttons}
            <Button
              color={mode === 0 ? 'primary' : 'default'}
              size="small"
              onClick={() => setMode(0)}
            >
              カスタム
            </Button>
            <Button
              color={mode === 168 ? 'primary' : 'default'}
              size="small"
              onClick={() => setMode(168)}
            >
              過去1週間
            </Button>
            <Button
              color={mode === 24 ? 'primary' : 'default'}
              size="small"
              onClick={() => setMode(24)}
            >
              過去1日間
            </Button>
          </div>
          <div style={{ marginTop: '10px' }}>
            <div>
              <TextField
                label={'開始'}
                type="datetime-local"
                size="small"
                ref={startRef}
                value={toLocalIso(start)}
                onChange={e => {
                  const value = e?.target?.value

                  if (!value) return
                  setMode(0)
                  setRange(v => ({
                    ...v,
                    start: new Date(value),
                  }))
                }}
              ></TextField>
            </div>
            <TextField
              label={'終了'}
              type="datetime-local"
              size="small"
              ref={endRef}
              value={toLocalIso(end)}
              onChange={e => {
                const value = e?.target?.value

                if (!value) return
                setMode(0)
                setRange(v => ({
                  ...v,
                  end: new Date(value),
                }))
              }}
            ></TextField>
          </div>
        </div>
      </div>
      <Charts
        {...{
          allowDg,
          device,
          start,
          end,
          gmode,
          size,
        }}
      />
    </Wrap>
  )
}

const Table = styled.table`
  border: solid 1px #000000;
  border-collapse: collapse;
  td,
  th {
    border: solid 1px #868686;
  }
`

const convert = (mpa: number, gmode: GraphMode, pressure: number): number => {
  if (gmode === 'mpa') return mpa
  return ((mpa || 0) * pressure) / 98
}

export function downloadCsv(
  data: GraphData,
  vlabel: string,
  allowDg: boolean,
  device: Device,
) {
  console.log({ device })
  const name = device.info?.label ? `${device.info?.label}_` : ''

  const filename = `${device.id}_${name}${moment(data[0][0]).format(
    `YYYYMMDD_HHmmss`,
  )}.csv`

  download(makeCSV(data, vlabel, allowDg), filename)
}

type ChartsProps = {
  start: Date
  end: Date
  device: Device
  size: SizeOption
  allowDg: boolean
  gmode: GraphMode
}
const unitsMap: Record<GraphMode, [string, string]> = {
  t: ['荷重', '(t)'],
  mpa: ['液圧', '(MPa)'],
}
const unitGap = ['変位', '(mm)']

function Charts({ size, start, end, device, gmode, allowDg }: ChartsProps) {
  const [data, setData] = useState<GraphData>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [ref, { width }] = useMeasure()
  const config = useSelector(getUserYuatsuConfig)
  const isMobile = useIsMobile()

  useEffect(() => {
    setLoading(true)
    loadSessionLogs(device, +start, +end).then(logs => {
      setLoading(false)
      setData(
        logs.map(v => {
          return [
            new Date(v.timestamp),
            round(
              convert(v.fields.mpa, gmode, config.pressureArea),
              config.pointMt,
            ),
            round(v.fields.len || 0, config.pointMm),
          ]
        }),
      )
    })
    // eslint-disable-next-line
  }, [device.id, start, end, gmode, config.pressureArea])

  if (loading) return <div>ロード中</div>
  if (data.length === 0) return <div>データがありません</div>

  const units = unitsMap[gmode]

  return (
    <Style data-mobile={isMobile} data-allowdial={allowDg}>
      <div style={{ gridArea: 'downl', textAlign: 'right' }}>
        <Button
          onClick={() => downloadCsv(data, units.join(''), allowDg, device)}
        >
          ダウンロード
        </Button>
      </div>
      <div className="table-col" style={{ height: '50vh' }}>
        <Table>
          <thead>
            <tr>
              <th rowSpan={2}>時刻</th>
              <th style={{ width: '60px' }}>{units[0]}</th>

              <th className="dial" style={{ width: '60px' }}>
                {unitGap[0]}
              </th>
            </tr>
            <tr>
              <th>{units[1]}</th>
              {allowDg && <th>{unitGap[1]}</th>}
            </tr>
          </thead>
          <tbody>
            {data.map(([time, v, mmv], i) => (
              <tr key={i}>
                <td>{moment(time).format(`YYYY/MM/DD HH:mm:ss`)}</td>
                <td>{v}</td>
                <td className="dial">{mmv}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div ref={ref} style={{ gridArea: 'chart' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            textAlign: 'center',
            marginTop: '12px',
          }}
        >
          <div style={{ color: colors[0] }}>
            <div>{units[0]}</div>
            <div>{units[1]}</div>
          </div>
          <div className="dial" style={{ color: colors[1] }}>
            <div>{unitGap[0]}</div>
            <div>{unitGap[1]}</div>
          </div>
        </div>
        <ChartBaseMemo
          {...{
            width,
            allowDg,
            end,
            start,
            size,
            units,
            data,
          }}
        />
      </div>
    </Style>
  )
}

const colors = ['blue', 'red']
const ChartBase = ({
  data,
  units,
  width,
  allowDg,
  size,
  end,
  start,
}: {
  data: any[][]
  units: string[]
  width: number | null
  allowDg: boolean
  size: SizeOption
  end: Date
  start: Date
}) => {
  const unit = units.join('')

  const compData = useMemo(() => {
    return [
      [
        { type: 'datetime', label: 'Time' },
        unit,
        ...(allowDg ? [unitGap.join('')] : []),
      ],
      ...data,
    ].map(v => v.slice(0, allowDg ? 3 : 2))
  }, [data, allowDg, unit])

  if (!width) return null

  return (
    <Chart
      width={width}
      height={width * 0.6}
      chartType="LineChart"
      loader={<div>ロード中</div>}
      data={compData}
      options={{
        legend: { position: 'none' },
        chartArea: { width: '80%', height: '80%' },
        series: [
          { targetAxisIndex: 0, axis: units.join('\n') },
          { targetAxisIndex: 1, axis: unitGap.join('\n') },
        ],
        colors,
        vAxes: {
          0: {
            viewWindow: { max: size.max, min: size.min },
            textStyle: {
              color: colors[0],
            },
          },
          1: {
            viewWindow: { min: size.min2, max: size.max2 },
            textStyle: {
              color: colors[1],
            },
          },
        },
        hAxis: {
          viewWindow: { max: end, min: start },
          format: 'dd日\nHH:mm',

          textStyle: {
            fontSize: 8,
          },
        },
      }}
    />
  )
}
const ChartBaseMemo = React.memo(ChartBase)

const Style = styled.div`
  display: grid;
  grid-template-areas: '- downl' 'table chart';
  grid-template-columns: max-content 1fr;
  width: 100%;
  &[data-mobile='true'] {
    grid-template-areas: 'chart' 'table' 'downl';
    grid-template-columns: 1fr;
  }
  &[data-allowdial='false'] {
    .dial {
      display: none;
    }
  }

  .table-col {
    overflow-y: scroll;
    grid-area: table;
    border: solid 1px gray;
    padding: 2px;
  }
`

export default LogGraph
