import * as React from 'react'
import { connect } from 'react-redux'

import { Grid, Paper, Typography } from '@material-ui/core'

import { Redirect } from 'react-router-dom'
import { getAuth } from '../../state/Auth/selectors'
import { Auth, State } from '../../types'
import LoginForm from '../LoginForm'
import { useIsMobile } from '../../components/useIsMobile'

type Props = {
  auth: Auth
}

const LoginPage = (props: Props) => {
  const { auth } = props
  const isMobile = useIsMobile()

  if (!auth.loading && auth.authorized) return <Redirect to={'/'} />

  return (
    <div>
      {props.auth.loading && <span>loading</span>}
      {!props.auth.loading && (
        <Grid container justify={'center'}>
          <Paper style={{ padding: '20px 40px' }}>
            <section>
              <Typography variant={isMobile ? 'h5' : 'h4'}>
                エキアツミエルカ
              </Typography>
              <hr />
              <LoginForm />
            </section>
          </Paper>
        </Grid>
      )}
    </div>
  )
}

const ms = (state: State) => ({ auth: getAuth(state) })

const conn = connect(ms)

export default conn(LoginPage)
