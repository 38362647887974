import { Box, Button, Checkbox, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getLoginUser } from '../../../state/Auth/selectors'
import { getDeviceById } from '../../../state/DeviceById/selectors'
import { updateYuatsuField } from '../../../state/Firebase/operations'
import { getUserYuatsuConfig } from '../../../state/UserById/selectors'
import { AlertConfig, initAlertConfig } from '../../../types'
import { fromTon, toTon } from '../../../utils'
import { useTonMode } from '../../../utils/useLocalConfig'

type Props = {}

type Fields = Record<string, AlertConfig>

const rangeMm = { min: -20, max: 20 }
const rangeMpa = { min: 0, max: 100 }
const rangeMmError = `${rangeMm.min}～${rangeMm.max}の間で設定してください`
const rangeMpaError = `${rangeMpa.min}～${rangeMpa.max}の間で設定してください`

const fieldProps = {
  type: 'number',
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
  style: { margin: '0px 2px' },
} as const

const trimTail = (s: string, d: number) => {
  const res = s.replace(new RegExp(`(\\..{0,${d}}).*`), '$1')

  return res
}
// const val2Str = (v: AlertConfig['mm']['up']): string =>
// v === null ? '' : String(v)
const str2Val = (s: string, d: number): number | null =>
  ['', '-'].includes(s) ? null : Number(trimTail(s, d))
const empConf = { enabled: false, up: null, dw: null }

const nullOr = (v: number | null, fn: (v: number) => number) =>
  v === null ? null : fn(v)

function AlertForm(props: Props) {
  const config = useSelector(getUserYuatsuConfig)
  const [tonMode] = useTonMode()

  const user = useSelector(getLoginUser)
  const deviceById = useSelector(getDeviceById)
  const devices = Object.values(deviceById)
  const alertConfs = config.alertConfs || {}

  const initialValues = useMemo(() => {
    const init: Fields = {}
    const conv = (v: number | null) =>
      nullOr(v, v =>
        Number(
          trimTail(
            String(tonMode ? toTon(v, config.pressureArea) : v),
            config.pointMt,
          ),
        ),
      )

    devices.forEach(v => {
      if (!alertConfs[v.id]) {
        init[v.id] = initAlertConfig
        return
      }

      const v2 = alertConfs[v.id]

      init[v.id] = {
        mm: v2.mm.enabled ? v2.mm : empConf,
        mpa: v2.mpa.enabled
          ? { ...v2.mpa, up: conv(v2.mpa.up), dw: conv(v2.mpa.dw) }
          : empConf,
      }
    })
    return init
  }, [devices, alertConfs, tonMode, config.pressureArea, config.pointMt])

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormik<Fields>({
    initialValues,
    validate: values => {
      const errors: Record<string, string> = {}

      devices.forEach(({ id }) => {
        const { mm, mpa } = values[id]
        const { up: mpUp, dw: mpDw } = mpa
        const { up: mmUp, dw: mmDw } = mm

        if (user.allowDialGauge && mm.enabled) {
          if (mmUp === null && mmDw === null) {
            errors[`${id}.mm.up`] = '下限上限どちらかには値をセットしてください'
            errors[`${id}.mm.dw`] = '!'
          }
          if (mmUp !== null && mmDw !== null) {
            if (mmUp <= mmDw) {
              errors[`${id}.mm.up`] = '下限値は上限値未満に設定してください'
              errors[`${id}.mm.dw`] = '!'
            }
          }
          if (mmUp !== null && !(rangeMm.min <= mmUp && mmUp <= rangeMm.max)) {
            errors[`${id}.mm.up`] = rangeMmError
            errors[`${id}.mm.dw`] = '!'
          }
          if (mmDw !== null && !(rangeMm.min <= mmDw && mmDw <= rangeMm.max)) {
            errors[`${id}.mm.up`] = rangeMmError
            errors[`${id}.mm.dw`] = '!'
          }
        }
        console.log(`validate ${id}`)
        console.log(mpa.enabled)
        if (mpa.enabled) {
          if (mpUp === null && mpDw === null) {
            errors[`${id}.mpa.up`] =
              '下限上限どちらかには値をセットしてください'
            errors[`${id}.mpa.dw`] = '!'
          }
          if (mpUp !== null && mpDw !== null) {
            if (mpUp <= mpDw) {
              errors[`${id}.mpa.up`] = '下限値は上限値未満に設定してください'
              errors[`${id}.mpa.dw`] = '!'
            }
          }
          if (
            mpUp !== null &&
            !(rangeMpa.min <= mpUp && mpUp <= rangeMpa.max)
          ) {
            errors[`${id}.mpa.up`] = rangeMpaError
            errors[`${id}.mpa.dw`] = '!'
          }
          if (
            mpDw !== null &&
            !(rangeMpa.min <= mpDw && mpDw <= rangeMpa.max)
          ) {
            errors[`${id}.mpa.up`] = rangeMpaError
            errors[`${id}.mpa.dw`] = '!'
          }
        }
      })

      return errors
    },
    onSubmit: (alertConfs: Fields, { setSubmitting, setErrors }) => {
      setSubmitting(true)

      const res: Fields = {}
      const conv = (v: number | null) =>
        nullOr(v, v => (tonMode ? fromTon(v, config.pressureArea) : v))

      devices.forEach(v => {
        const v2 = alertConfs[v.id]

        res[v.id] = {
          mm: v2.mm.enabled ? v2.mm : empConf,
          mpa: v2.mpa.enabled
            ? { ...v2.mpa, up: conv(v2.mpa.up), dw: conv(v2.mpa.dw) }
            : empConf,
        }
      })

      updateYuatsuField(user.id, { alertConfs: res }).then(() => {
        setSubmitting(false)
      })
    },
  })

  console.log(errors)

  const toj = a => JSON.stringify(a)
  const changed = toj(values) !== toj(initialValues)

  // const change = (title, e) => {
  //   e.persist()
  //   handleChange(e)
  //   setFieldTouched(title, true, false)
  // }

  const cidCov = (v: string) => {
    const [ci, ri] = v.split('_').map(Number)

    return { cid: `${ri + 1}-${ci + 1}`, pt: ri * 100 + ci }
  }
  const cellsByDid: Record<string, boolean> = {}
  const cellsItems = Object.entries(config.cells)
    .map(([cid, did]) => [cidCov(cid), did] as const)
    .sort(([{ pt: a }], [{ pt: b }]) => a - b)
    .map(([{ cid }, did]) => {
      cellsByDid[did] = true
      return {
        device: deviceById[did],
        cid,
        conf: values[did],
      }
    })

  const items = cellsItems
    .concat
    // devices
    //   .filter(d => !cellsByDid[d.id])
    //   .map(device => ({
    //     device,
    //     cid: '-',
    //     conf: values[device.id],
    //   })),
    ()

  if (items.some(v => !v.device)) {
    return <Typography>ロード中</Typography>
  }

  const str2ValMm = (s: string, mm: boolean) =>
    str2Val(s, mm ? config.pointMm : config.pointMt)

  const makeInputProps = (id: string, mm = false) =>
    ({
      name: id,
      error: !!errors[id],
      onChange: e => {
        setFieldValue(
          id,
          e.target.value.length === 0 ? null : str2ValMm(e.target.value, mm),
        )
      },
    } as const)
  const label = tonMode ? '荷重(t)' : '液圧(MPa)'
  // const label2 = tonMode ? '荷重' : '液圧'

  const valid = Object.values(errors).length === 0
  const submittable = !isSubmitting && changed && valid

  return (
    <Style data-dg={user.allowDialGauge}>
      <Box style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr' }}>
        <Box>
          <Typography>計測値が上閾値〜下閾値の範囲から外れた場合に</Typography>
          <Typography style={{ fontWeight: 'bold', paddingLeft: '12px' }}>
            ①ログイン時に入力したアドレス
            <br />
            ②申し込みいただいたメールアドレス(最大5つ)
          </Typography>
          <Typography>宛にアラートメールを送信します。</Typography>
          <Typography variant="caption">
            ※「alert@ekiatsu.mieruka.online」からのメールを受信できるようにしてください。
          </Typography>
        </Box>
        <Box>
          <img src="/alert-desc.png" alt="alert-desc" />
        </Box>
      </Box>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            disabled={!submittable}
            onClick={e => handleSubmit()}
          >
            設定する
          </Button>
        </div>
        <Box className="row header header-bottom">
          <div>
            <Typography>デバイス名</Typography>
            <Typography>(配置座標)</Typography>
          </div>
          <div>
            <div className="row-unit">
              <div>
                <div></div>
                <div>
                  <Typography>閾値</Typography>
                  <DoubleBox>
                    <Typography>下限</Typography>
                    <Typography>上限</Typography>
                  </DoubleBox>
                </div>
                <div>
                  <Typography>監視</Typography>
                  <Typography>ON/OFF</Typography>
                </div>
              </div>
            </div>
          </div>
        </Box>
        {items.map(({ device, cid, conf }) => (
          <Box key={device.id} className="row">
            <div>
              <Typography>{device.id}</Typography>
              <Typography>({cid})</Typography>
            </div>
            <div className="row-unit">
              <div data-enabled={values[device.id].mpa.enabled}>
                <Typography>{label}</Typography>
                <DoubleBox>
                  <TextField
                    {...fieldProps}
                    {...makeInputProps(`${device.id}.mpa.dw`)}
                    value={values[device.id].mpa.dw ?? ''}
                    disabled={!values[device.id].mpa.enabled}
                  />
                  <TextField
                    {...fieldProps}
                    {...makeInputProps(`${device.id}.mpa.up`)}
                    value={values[device.id].mpa.up ?? ''}
                    disabled={!values[device.id].mpa.enabled}
                  />
                  <Typography variant="caption" className="help">
                    {errors[`${device.id}.mpa.up`]}
                  </Typography>
                </DoubleBox>
                <Checkbox
                  checked={values[device.id].mpa.enabled}
                  name={`${device.id}.mpa.enabled`}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue(`${device.id}.mpa.enabled`, true)
                    } else {
                      setFieldValue(`${device.id}.mpa`, {
                        up: null,
                        dw: null,
                        enabled: false,
                      })
                      // setFieldError(`${device.id}.mpa.up`, undefined)
                      // setFieldError(`${device.id}.mpa.dw`, undefined)
                    }
                  }}
                />
              </div>
              <div
                data-enabled={values[device.id].mm.enabled}
                className="dg-row"
              >
                <Typography>変位(mm)</Typography>
                <DoubleBox>
                  <TextField
                    {...fieldProps}
                    {...makeInputProps(`${device.id}.mm.dw`, true)}
                    value={values[device.id].mm.dw ?? ''}
                    disabled={!values[device.id].mm.enabled}
                  />
                  <TextField
                    {...fieldProps}
                    {...makeInputProps(`${device.id}.mm.up`, true)}
                    value={values[device.id].mm.up ?? ''}
                    disabled={!values[device.id].mm.enabled}
                  />
                  <Typography variant="caption" className="help">
                    {errors[`${device.id}.mm.up`]}
                  </Typography>
                </DoubleBox>
                <Checkbox
                  checked={values[device.id].mm.enabled}
                  name={`${device.id}.mm.enabled`}
                  // onChange={change.bind(null, `${device.id}.mm.enabled`)}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue(`${device.id}.mm.enabled`, true)
                    } else {
                      console.log('chenaged')
                      setFieldValue(`${device.id}.mm`, {
                        up: null,
                        dw: null,
                        enabled: false,
                      })
                    }
                  }}
                />
              </div>
            </div>
          </Box>
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            disabled={!submittable}
            onClick={e => handleSubmit()}
          >
            設定する
          </Button>
        </div>
      </form>
    </Style>
  )
}

const Style = styled.div`
  .row {
    display: grid;
    grid-template-columns: 180px 1fr;
  }
  .row-unit {
    > div {
      display: grid;
      grid-template-columns: 120px 280px 100px;
      > p:nth-child(1) {
        padding-top: 8px;
        text-align: right;
      }
    }
    p {
      text-align: center;
    }
    .MuiInputBase-input {
      padding: 4px;
    }
    [data-enabled='false'] {
      input {
        color: #ccc;
        background: #ccc;
      }
    }
  }
  .header {
    p {
      font-weight: bold;
    }
  }
  .header-bottom {
    border-bottom: 1px solid #888;
  }

  img {
    width: 100%;
  }

  &[data-dg='false'] {
    .dg-row {
      display: none;
    }
  }
  .q-mark {
    color: white;
    background: #aaa;
    border-radius: 50%;
    padding: 0 4px;
    font-size: 12px;
  }
`

const DoubleBox = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  position: relative;
  .MuiTextField-root {
    height: 52px;
  }

  .help {
    position: absolute;
    bottom: -2px;
    font-size: 12px;
    color: red;
  }
`

export default AlertForm
